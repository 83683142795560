import { Button, ButtonProperties } from 'components/ui/button';
import { cn } from 'helpers/utils';

interface Properties {
  buttons: ButtonProperties[];
  isRow?: boolean;
}

export const ButtonGroup = ({ buttons, isRow }: Properties) => (
  <div className={cn('flex flex-col gap-2 w-full', isRow && 'flex-row justify-evenly')}>
    {buttons.map((button, index) => (
      <Button className="w-full" key={index} {...button} />
    ))}
  </div>
);
