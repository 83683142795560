import * as React from 'react';

import { cn } from 'helpers/utils';

export interface InputProperties extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProperties>(({ className, type, ...properties }, reference) => (
  <input
    type={type}
    className={cn(
      'flex min-h-9 w-full rounded-md border border-input-border bg-input-background px-3 py-2 transition-colors file:border-0 file:bg-input-background file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 shadow-inner !text-base',
      className
    )}
    ref={reference}
    {...properties}
  />
));
Input.displayName = 'Input';

export { Input };
