'use client';

import { ButtonGroup } from 'components/ButtonGroup';
import { ButtonProperties } from 'components/ui/button';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { Form as FormProvider } from 'components/ui/form';
import { ReactNode } from 'react';

interface Properties<T extends FieldValues> {
  buttonLabel?: string;
  children?: ReactNode;
  error?: string;
  extraButtons?: ButtonProperties[];
  form: UseFormReturn<T, any, any>;
  isLoading?: boolean;
  onSubmit: (values: T) => void;
  hideButtons?: boolean;
}

export const Form = <T extends FieldValues>({
  buttonLabel = 'Opslaan',
  children,
  error,
  extraButtons,
  form,
  isLoading,
  onSubmit,
  hideButtons = false,
}: Properties<T>) => (
  <FormProvider {...form}>
    <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col justify-between flex-1 gap-4">
      <div className="flex flex-col gap-4 pb-24">{children}</div>
      {!hideButtons && (
        <div className="fixed bottom-0 left-0 flex flex-col w-full gap-2 p-4">
          <ButtonGroup buttons={[{ children: buttonLabel, type: 'submit', isLoading }, ...(extraButtons || [])]} />
          {error ? <p className="text-sm text-destructive">{error}</p> : undefined}
        </div>
      )}
    </form>
  </FormProvider>
);
